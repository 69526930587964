import { createStore } from 'vuex'
import * as auth from './auth'
import * as route from './route'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: {
      namespaced: true,
      ...auth
    },
    route: {
      namespaced: true,
      ...route
    }
  }
})
