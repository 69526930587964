function calculateMeshCodes({ lat, lng }) {
  const mesh80km = (
    Math.floor(lat * 1.5) * 100 +
    Math.floor(lng - 100)
  ).toString()
  const mesh10km = (
    Math.floor((lat * 12) / 8) * 10000 +
    Math.floor(((lng - 100) * 8) / 8) * 100 +
    Math.floor((lat * 12) % 8) * 10 +
    (Math.floor((lng - 100) * 8) % 8)
  ).toString()
  const mesh1km = (
    Math.floor((lat * 120) / 80) * 1000000 +
    Math.floor(lng - 100) * 10000 +
    Math.floor(((lat * 120) % 80) / 10) * 1000 +
    Math.floor((((lng - 100) * 80) % 80) / 10) * 100 +
    Math.floor((lat * 120) % 10) * 10 +
    (Math.floor((lng - 100) * 80) % 10)
  ).toString()
  const mesh250m = (
    Math.floor((lat * 480) / 320) * 100000000 +
    Math.floor(((lng - 100) * 320) / 320) * 1000000 +
    Math.floor(((lat * 480) % 320) / 40) * 100000 +
    Math.floor((((lng - 100) * 320) % 320) / 40) * 10000 +
    Math.floor(((lat * 480) % 40) / 4) * 1000 +
    Math.floor((((lng - 100) * 320) % 40) / 4) * 100 +
    Math.round((lat * 480) % 4) * 10 +
    Math.floor(((lng - 100) * 320) % 4) +
    1
  ).toString() // cause error if floor is used instead of round

  return { mesh80km, mesh10km, mesh1km, mesh250m }
}

module.exports = { calculateMeshCodes }
