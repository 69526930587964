export const state = () => ({
  prevPath: null
})

export const mutations = {
  setPrevPath(state, value) {
    state.prevPath = value
  },
  resetState(state) {
    state.prevPath = null
  }
}
