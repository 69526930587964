let config = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG_JSON)
let api = process.env.VUE_APP_API_URL
let mapsKey = process.env.VUE_APP_MAPS_KEY
const isDev =
  api === 'https://asia-northeast1-spotwork-dev-5da3e.cloudfunctions.net'
const liffId = process.env.VUE_APP_LIFF_ID
const comeonLiffId = process.env.VUE_APP_COME_ON_LIFF_ID
const comeonRedirectUrl = process.env.VUE_APP_COME_ON_REDIRECT_URL
const redirectUrl = process.env.VUE_APP_LIFF_REDIRECT_URL
const siteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY

export {
  config,
  api,
  mapsKey,
  isDev,
  liffId,
  comeonLiffId,
  comeonRedirectUrl,
  redirectUrl,
  siteKey
}
