import { firestore, webFirestore } from '@/components/utils/firebase'
import { initializeDatabase } from '@shared/database'
import { createApp } from 'vue'
import VueClipboard from 'vue3-clipboard'
import router from './router'
import App from './App.vue'
import store from './store'
// import VueQuagga from 'vue-quaggajs'
import { EventBus } from './eventBus.js'
import vuetify from '@/plugins/vuetify'

const app = createApp(App)
app.config.globalProperties.$EventBus = EventBus

initializeDatabase(firestore, { FieldValue: null }, true, webFirestore)

if (process.env.NODE_ENV === 'local') {
  firestore.useEmulator('localhost', 5050)
}

// https://t-salad.com/vue-forbidden-scroll/
// イベント設定用関数
function noScroll(event) {
  event.preventDefault()
}
// スクロール禁止
app.config.globalProperties.$noScroll = function () {
  // SP
  document.addEventListener('touchmove', noScroll, { passive: false })
  // PC
  document.addEventListener('mousewheel', noScroll, { passive: false })
}
// スクロール禁止を解除
app.config.globalProperties.$returnScroll = function () {
  // SP
  document.removeEventListener('touchmove', noScroll, { passive: false })
  // PC
  document.removeEventListener('mousewheel', noScroll, { passive: false })
}

// Cancel可能なタイマー
app.config.globalProperties.$asyncSetTimeout = function (
  msec,
  func = () => {}
) {
  let timeoutId
  let r
  const exec = () =>
    new Promise((res) => {
      r = res
      timeoutId = setTimeout(async () => {
        timeoutId = null
        await func()
        res()
      }, msec)
    })
  return {
    exec,
    cancel: () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
        timeoutId = null
        r()
      }
    }
  }
}

// イベント判定関数
app.config.globalProperties.$getCampaignName = function () {
  let nowDateObj = new Date()

  // secs202311
  let secs202311StartDateObj = new Date()
  let secs202311EndDateObj = new Date()
  secs202311StartDateObj.setFullYear(2023)
  secs202311StartDateObj.setMonth(10) // 2月の場合は1
  secs202311StartDateObj.setDate(1)
  secs202311StartDateObj.setHours(9)
  secs202311StartDateObj.setMinutes(0)
  secs202311EndDateObj.setFullYear(2023)
  secs202311EndDateObj.setMonth(10)
  secs202311EndDateObj.setDate(30)
  secs202311EndDateObj.setHours(23)
  secs202311EndDateObj.setMinutes(59)

  if (
    nowDateObj.getTime() >= secs202311StartDateObj.getTime() &&
    nowDateObj.getTime() <= secs202311EndDateObj.getTime()
  ) {
    return 'secs202311'
  } else {
    return false
  }
}

// イベント判定関数
app.config.globalProperties.$isShopIdList = function (
  shopId,
  campaignShopList
) {
  for (let i = 0; i < campaignShopList.length; i++) {
    let campaignShop = campaignShopList[i]

    if (campaignShop.shopId == shopId) {
      return true
    }
  }
  return false
}

app.use(vuetify)

app.use(VueClipboard, { autoSetContainer: true, appendToBody: true })

app.use(router)
app.use(store)

app.mount('#app')
