import { reactive } from 'vue'

export const EventBus = reactive({
  events: {},
  emit(event, data) {
    if (this.events[event]) {
      this.events[event].forEach((callback) => callback(data))
    }
  },
  on(event, callback) {
    if (!this.events[event]) {
      this.events[event] = []
    } else {
      // 既に存在するリスナーを解除する
      this.off(event, callback)
    }
    this.events[event].push(callback)
  },
  off(event, callback) {
    if (this.events[event]) {
      const index = this.events[event].indexOf(callback)
      if (index > -1) {
        this.events[event].splice(index, 1)
      }
    }
  }
})
