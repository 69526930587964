import { config, api, siteKey } from './config'
import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import {
  initializeAppCheck,
  ReCaptchaV3Provider,
  getToken
} from 'firebase/app-check'
import {
  getFirestore,
  connectFirestoreEmulator,
  doc,
  collection,
  collectionGroup,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  runTransaction,
  writeBatch,
  increment,
  deleteField,
  arrayUnion,
  arrayRemove,
  startAt,
  endAt,
  serverTimestamp
} from 'firebase/firestore'
import firebase from 'firebase/compat/app'

const isLocal =
  window.location.hostname === 'localhost' ||
  window.location.hostname === '127.0.0.1'

// sign-inのため。 firebaseauthuiが不要であれば、消してよし。
firebase.initializeApp(config)

const app = initializeApp(config)

if (!isLocal) {
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(siteKey),
    isTokenAutoRefreshEnabled: true
  })

  getToken(appCheck)
    .then(() => {
      console.log('App Check Success')
    })
    .catch((error) => {
      console.log(error)
    })
}

const storage = getStorage(app)
if (process.env.NODE_ENV === 'local') {
  connectStorageEmulator(storage, 'localhost', 9199)
}

const auth = getAuth(app)
if (process.env.NODE_ENV === 'local') {
  connectAuthEmulator(auth, 'http://localhost', 9099)
}

const functions = getFunctions(app)
if (process.env.NODE_ENV === 'local') {
  connectFunctionsEmulator(functions, 'localhost', 5001)
}

const firestore = getFirestore(app)
if (process.env.NODE_ENV === 'local') {
  connectFirestoreEmulator(firestore, 'localhost', 5055)
}

const webFirestore = {
  doc: doc,
  collection: collection,
  collectionGroup: collectionGroup,
  getDoc: getDoc,
  getDocs: getDocs,
  query: query,
  where: where,
  orderBy: orderBy,
  limit: limit,
  addDoc: addDoc,
  setDoc: setDoc,
  updateDoc: updateDoc,
  deleteDoc: deleteDoc,
  onSnapshot: onSnapshot,
  runTransaction: runTransaction,
  writeBatch: writeBatch,
  increment: increment,
  deleteField: deleteField,
  arrayUnion: arrayUnion,
  arrayRemove: arrayRemove,
  startAt: startAt,
  endAt: endAt,
  serverTimestamp: serverTimestamp
}

export { firebase, storage, auth, api, functions, firestore, webFirestore }
